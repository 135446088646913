<template>
  <div id="app" class="container">
    <div class="mt-4 mb-4 text-center">
      <img src="@/assets/golook.jpg" alt="Go Look Logo" class="img-fluid" style="height: 300px;">
    </div>
    
    <!-- Tabs for Health Facilities, Schools, and Places of Worship -->
    <div class="tabs mb-3">
      <button @click="activeTab = 'about'" class="btn btn-light text-dark" :class="{ active: activeTab === 'about' }" style="background-color: lightgreen;">
        Home
      </button>
      <button @click="activeTab = 'healthCare'" class="btn btn-primary" :class="{ active: activeTab === 'healthCare' }">
        Health Facilities
      </button>
      <button @click="activeTab = 'schools'" class="btn btn-primary" :class="{ active: activeTab === 'schools' }">
        Schools
      </button>
      <button @click="activeTab = 'libraries'" class="btn btn-primary" :class="{ active: activeTab === 'libraries' }">
        Libraries
      </button>
      <button @click="activeTab = 'resorts'" class="btn btn-primary" :class="{ active: activeTab === 'resorts' }">
        Resorts
      </button>
      <button @click="activeTab = 'swimmingpools'" class="btn btn-primary" :class="{ active: activeTab === 'swimmingpools' }">
        Swimming Pools
      </button>
      <button @click="activeTab = 'placesOfWorship'" class="btn btn-primary" :class="{ active: activeTab === 'placesOfWorship' }">
        Places of Worship
      </button>
      <button @click="activeTab = 'damLevels'" class="btn btn-primary" :class="{ active: activeTab === 'damLevels' }">
        Dam Levels
      </button>
      <button @click="activeTab = 'publicWiFi'" class="btn btn-primary" :class="{ active: activeTab === 'publicWiFi' }">
        Public Wi-Fi
      </button>
      <button @click="activeTab = 'apiList'" class="btn btn-light text-dark" :class="{ active: activeTab === 'apiList' }" style="background-color: lightgreen;">
        API List
      </button>
    </div>

     <!-- Centered Content -->
  <div class="row justify-content-center">
    <div class="col-md-10"> <!-- Adjust the column size as needed -->
    <!-- Dam Levels Tab -->
    <div v-if="activeTab === 'damLevels'">
      <h3>Select a Date</h3>
      <Flatpickr v-model="selectedDate" :config="{ dateFormat: 'Y-m-d' }"></Flatpickr>
    <div class="mt-3"> 
      <button @click="fetchDamLevels" class="btn btn-success mt-3">Submit</button>
    </div>

    <!-- Table to display dam levels results -->
    <div v-if="damLevels.length > 0" class="mt-3">
        <h4>Dam Levels on {{ selectedDate }}</h4>
        <table class="table table-striped table-hover">
          <thead class="table-light">
            <tr>
              <th>Dam Name</th>
              <th>Reading Date</th>
              <th>Level (%)</th>
              <th></th>
              <th>Previous Year's Level (%)</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="dam in damLevels" :key="dam.DamName">
              <td>{{ dam.DamName }}</td>
              <td>{{ dam.ReadingDate }}</td>
              <td>{{ dam.Current }}</td>
              <td>
                <CircleProgress
                  :percent="Math.min(dam.Current,100)"
                  :size="60"
                  :strokeWidth="10"
                  :bgColor="'#e0e0e0'"
                  :fill="'#76c7c0'"
                  :rounded="true">
                  <template #default="{ percentage }">
                    <span class="circle-label">{{ percentage }}%</span>
                  </template>
                </CircleProgress>
              </td>
              <td>{{ dam.LastYear }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

  </div>

    <!-- Health Facilities Tab -->
    <div v-if="activeTab === 'healthCare'">
      <h3>Health Care Facilities</h3>
      <input v-model="searchQueryHealth" class="form-control mb-3" placeholder="Search by Name" />
      
      <!-- Table for Health Facilities -->
      <div class="table-responsive">
        <table class="table table-striped table-hover">
          <thead class="table-light">
            <tr>
              <th>Name</th>
              <th>Type</th>
              <th>Address</th>
              <th>Class</th>
              <th>Maps</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="facility in filteredHealthFacilities" :key="facility.ObjectID">
              <td>{{ facility.Name }}</td>
              <td>{{ facility.Type }}</td>
              <td>{{ facility.Address }}</td>
              <td>{{ facility.Class }}</td>
              <td>
                <a :href="'https://www.google.com/maps?q=' + facility.Latitude + ',' + facility.Longitude" target="_blank">
                  View on Maps
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <!-- Libraries Tab -->
    <div v-if="activeTab === 'libraries'">
      <h3>Libraries</h3>
      <input v-model="searchQueryLibraries" class="form-control mb-3" placeholder="Search by Name" />
      
      <!-- Table for Health Facilities -->
      <div class="table-responsive">
        <table class="table table-striped table-hover">
          <thead class="table-light">
            <tr>
              <th>Name</th>
              <th>CTGY</th>
              <th>Members</th>
              <th>Area Sqm</th>
              <th>Maps</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="library in filteredLibraries" :key="library.ObjectID">
              <td>{{ library.Name }}</td>
              <td>{{ library.CTGY }}</td>
              <td>{{ library.Members }}</td>
              <td>{{ library.Area_SQM }}</td>
              <td>
                <a :href="'https://www.google.com/maps?q=' + library.X + ',' + library.Y" target="_blank">
                  View on Maps
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <!-- Resorts Tab -->
    <div v-if="activeTab === 'resorts'">
      <h3>Cape Town Resorts</h3>
      <input v-model="searchQueryResorts" class="form-control mb-3" placeholder="Search by Name" />
      
      <!-- Table for Resorts -->
      <div class="table-responsive">
        <table class="table table-striped table-hover">
          <thead class="table-light">
            <tr>
              <th>Name</th>
              <th>District</th>
              <th>Maps</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="resort in filteredResorts" :key="resort.ObjectID">
              <td>{{ resort.Name }}</td>
              <td>{{ resort.District }}</td>
              <td>
                <a :href="'https://www.google.com/maps?q=' + resort.X + ',' + resort.Y" target="_blank">
                  View on Maps
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <!-- Swimming Pools Tab -->
    <div v-if="activeTab === 'swimmingpools'">
      <h3>Cape Town Swimming Pools</h3>
      <input v-model="searchQuerySwimmingPools" class="form-control mb-3" placeholder="Search by Name" />
      
      <!-- Table for Swimming Pools -->
      <div class="table-responsive">
        <table class="table table-striped table-hover">
          <thead class="table-light">
            <tr>
              <th>Name</th>
              <th>Number</th>
              <th>Maps</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="swimmingpool in filteredSwimmingPools" :key="swimmingpool.ObjectID">
              <td>{{ swimmingpool.Name }}</td>
              <td>{{ swimmingpool.Number }}</td>
              <td>
                <a :href="'https://www.google.com/maps?q=' + swimmingpool.X + ',' + swimmingpool.Y" target="_blank">
                  View on Maps
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <!-- Schools Tab -->
    <div v-if="activeTab === 'schools'">
      <h3>Schools</h3>
      <input v-model="searchQuerySchools" class="form-control mb-3" placeholder="Search by Name, Control, or District" />
      
      <!-- Table for Schools -->
      <div class="table-responsive">
        <table class="table table-striped table-hover">
          <thead class="table-light">
            <tr>
              <th>Name</th>
              <th>Type</th>
              <th>Control</th>
              <th>Education District</th>
              <th>Medium Of Instruction</th>
              <th>Maps</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="school in filteredSchools" :key="school.ObjectID">
              <td>{{ school.Name }}</td>
              <td>{{ school.SchoolType }}</td>
              <td>{{ school.Control }}</td>
              <td>{{ school.EducationDistrict }}</td>
              <td>{{ school.MediumOfInstruction }}</td>
              <td>
                <a :href="'https://www.google.com/maps?q=' + school.Latitude + ',' + school.Longitude" target="_blank">
                  View on Maps
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <!-- Places of Worship Tab -->
    <div v-if="activeTab === 'placesOfWorship'">
      <h3>Places of Worship</h3>
      <input v-model="searchQueryWorship" class="form-control mb-3" placeholder="Search by Name" />
      
      <!-- Table for Places of Worship -->
      <div class="table-responsive">
        <table class="table table-striped table-hover">
          <thead class="table-light">
            <tr>
              <th>Name</th>
              <th>Type</th>
              <th>Religion</th>
              <th>Suburb</th>
              <th>Street</th>
              <th>Maps</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="place in filteredPlacesOfWorship" :key="place.ObjectID">
              <td>{{ place.Name }}</td>
              <td>{{ place.Building_Type }}</td>
              <td>{{ place.Religion }}</td>
              <td>{{ place.Suburb }}</td>
              <td>{{ place.Street_Name }}</td>
              <td>
                <a :href="'https://www.google.com/maps?q=' + place.Latitude + ',' + place.Longitude" target="_blank">
                  View on Maps
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <!-- Public Wi-Fi Tab -->
    <div v-if="activeTab === 'publicWiFi'">
      <h3>Public Wi-Fi Sites</h3>
      <input v-model="searchQueryWiFi" class="form-control mb-3" placeholder="Search by Site Name or Town" />
      
      <!-- Table for Public Wi-Fi Sites -->
      <div class="table-responsive">
        <table class="table table-striped table-hover">
          <thead class="table-light">
            <tr>
              <th>Site Name</th>
              <th>Suburb</th>
              <th>Address</th>
              <th>Department</th>
              <th>Town</th>
              <th>Municipality</th>
              <th>Maps</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="wifi in filteredWiFiSites" :key="wifi.ObjectID">
              <td>{{ wifi.SiteName }}</td>
              <td>{{ wifi.Suburb }}</td>
              <td>{{ wifi.Address }}</td>
              <td>{{ wifi.Department }}</td>
              <td>{{ wifi.Town }}</td>
              <td>{{ wifi.Municipality }}</td>
              <td>
                <a :href="'https://www.google.com/maps?q=' + wifi.Latitude + ',' + wifi.Longitude" target="_blank">
                  View on Maps
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>


    <!-- API List Tab -->
    <div v-if="activeTab === 'apiList'" class="table-responsive">
      <h3>API List</h3>
      <p>To gain access to the APIs below, please follow the links:</p>
      <table class="table table-striped table-hover">
        <thead class="table-light">
          <tr>
            <th>DataSet</th>
            <th>Description</th>
            <th>API Link</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Health Facilities</td>
            <td>A comprehensive list of health care facilities in the Cape Town area including hospitals and clinics.</td>
            <td><a href="https://rapidapi.com/brownrd/api/cape-town-health-care-facilities/playground/apiendpoint_34fbcc63-c1f7-405a-81dc-eb8acc0dbca3" target="_blank">Access API</a></td>
          </tr>
          <tr>
            <td>Schools</td>
            <td>A dataset providing information about schools in Cape Town, including school types, districts, and control.</td>
            <td><a href="https://rapidapi.com/brownrd/api/cape-town-schools/playground/apiendpoint_e5395ba1-0c31-4c63-9a0d-9fd8b087cbb8" target="_blank">Access API</a></td>
          </tr>
          <tr>
            <td>Places of Worship</td>
            <td>A list of places of worship across different religions, including churches and mosques in the Cape Town area.</td>
            <td><a href="https://rapidapi.com/brownrd/api/cape-town-places-of-worship/playground/apiendpoint_1a231573-64a6-4b31-8123-b1435db8c3e5" target="_blank">Access API</a></td>
          </tr>
          <tr>
            <td>Dam Levels</td>
            <td>A list of all the Western Cape dams with their levels for a selected reading date.</td>
            <td><a href="https://rapidapi.com/brownrd/api/cape-town-dam-levels/playground/apiendpoint_5c692ce3-80ab-4b4f-8cf9-fd02c7ff0d93" target="_blank">Access API</a></td>
          </tr>
          <tr>
            <td>Public Wi-FI Locations</td>
            <td>A list of all the Western Cape public Wi-Fi locations.</td>
            <td><a href="https://rapidapi.com/brownrd/api/western-cape-public-wi-fi-locations/playground/apiendpoint_9f388b7f-6365-46c5-8408-7eba48d8a6ce" target="_blank">Access API</a></td>
          </tr>
        </tbody>
      </table>
    </div>

    <!-- About Tab -->
<div v-if="activeTab === 'about'" class="table-responsive">
  <h3>About</h3>
  <p>Welcome to GoLook, A data platform dedicated to making government data accessible, usable, and impactful. By transforming raw datasets from the Western Cape Government, City Of Cape Town and other open data portals into accessible APIs and structured datasets, we aim to empower innovators, researchers, and organizations with the information they need to address pressing social, economic, and environmental challenges in South Africa. 

<br><br> At GoLook, we believe that data is a powerful tool for transparency, accountability, and progress. Our platform curates, cleans, and organizes data, converting it into actionable insights that fuel new solutions for local issues—from improving public services to fostering economic growth and supporting sustainable development. We’re building a bridge between government data and those who seek to use it to make a difference, enabling data-driven decision-making that brings measurable benefits to communities across the nation. 

Explore our resources and join us in using data to unlock new opportunities and solutions for South Africa's future.</p>
  <p>To gain access to the APIs, please follow the links on API List or email  
    <a href="mailto:brownrd@golook.co.za">GoLook</a>.
  </p>
</div>

  </div> <!-- End of Column -->
</div> <!-- End of Row -->
  
</template>

<script>
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.css';
import Flatpickr from 'vue-flatpickr-component'; // Import Flatpickr
import 'flatpickr/dist/flatpickr.css'; // Import Flatpickr styles
import CircleProgress from 'vue3-circle-progress';

export default {
  data() {
    return {
      activeTab: 'about', // Default tab
      selectedDate: '2024-08-23',
      damLevels: [],
      healthFacilities: [],
      schools: [],
      placesOfWorship: [],
      libraries: [],
      searchQueryHealth: '',
      searchQuerySchools: '',
      searchQueryWorship: '',
      publicWiFiSites: [],
      searchQueryWiFi: '',
      searchQueryLibraries: '',
      resorts: [],
      searchQueryResorts: '',
      swimmingpool:[],
      searchQuerySwimmingPools: ''
    };
  },
  components: {
    Flatpickr,  // Register the DatePicker component
    CircleProgress, // Register the CircleProgress component
  },
  computed: {
    filteredHealthFacilities() {
      return this.healthFacilities.filter(facility =>
        facility.Name.toLowerCase().includes(this.searchQueryHealth.toLowerCase())
      );
    },
    filteredSchools() {
      return this.schools.filter(school =>
        school.Name.toLowerCase().includes(this.searchQuerySchools.toLowerCase()) ||
        school.Control.toLowerCase().includes(this.searchQuerySchools.toLowerCase()) ||
        school.EducationDistrict.toLowerCase().includes(this.searchQuerySchools.toLowerCase())
      );
    },
    filteredPlacesOfWorship() {
      return this.placesOfWorship.filter(place =>
        place.Name.toLowerCase().includes(this.searchQueryWorship.toLowerCase())
      );
    },
    filteredWiFiSites() {
      return this.publicWiFiSites.filter(wifi =>
        wifi.SiteName.toLowerCase().includes(this.searchQueryWiFi.toLowerCase()) ||
        wifi.Town.toLowerCase().includes(this.searchQueryWiFi.toLowerCase())
      );
    },
    filteredLibraries() {
      return this.libraries.filter(library =>
        library.Name.toLowerCase().includes(this.searchQueryLibraries.toLowerCase()) ||
        library.CTGY.toLowerCase().includes(this.searchQueryLibraries.toLowerCase())
      );
    },
    filteredResorts() {
      return this.resorts.filter(resort =>
        resort.Name.toLowerCase().includes(this.searchQueryResorts.toLowerCase()) ||
        resort.District.toLowerCase().includes(this.searchQueryResorts.toLowerCase())
      );
    },
    filteredSwimmingPools() {
      return this.swimmingpool.filter(swimmingpool =>
        swimmingpool.Name.toLowerCase().includes(this.searchQuerySwimmingPools.toLowerCase()) ||
        swimmingpool.Number.toLowerCase().includes(this.searchQuerySwimmingPools.toLowerCase())
      );
    }
  },
  methods: {
    async fetchHealthFacilities() {
      try {
        const response = await axios.get('https://yysvjjiplxvbxfiaoevtocnrw40sgcbh.lambda-url.us-east-1.on.aws');
        this.healthFacilities = response.data;
      } catch (error) {
        console.error('Error fetching health facilities:', error);
      }
    },
    async fetchSchools() {
      try {
        const response = await axios.get('https://pqenhm535nwla5lbnzyw6dj23y0dqgoz.lambda-url.us-east-1.on.aws');
        this.schools = response.data;
      } catch (error) {
        console.error('Error fetching schools:', error);
      }
    },
    async fetchPlacesOfWorship() {
      try {
        const response = await axios.get('https://uxsssjxgj5xzcvbolobiowtde40sffwa.lambda-url.us-east-1.on.aws');
        this.placesOfWorship = response.data;
      } catch (error) {
        console.error('Error fetching places of worship:', error);
      }
    },
    async fetchDamLevels() {
      const formattedDate = this.selectedDate;
      if (formattedDate) {
        const url = `https://xi3ivv4abwt5djspmcweev6iii0ihtez.lambda-url.us-east-1.on.aws/?leveldate=${formattedDate}`;
        try {
          const response = await axios.get(url);
          this.damLevels = response.data;  // Store the fetched data
        } catch (error) {
          console.error('Error fetching dam levels:', error);
        }
      } else {
        alert('Please select a date.');
      }
    },
    async fetchPublicWiFiSites() {
      try {
        const response = await axios.get('https://aslzvaz6kzssi7zpjchgzwvpqi0zvhrc.lambda-url.us-east-1.on.aws');
        this.publicWiFiSites = response.data;
      } catch (error) {
        console.error('Error fetching public Wi-Fi sites:', error);
      }
    },
    async fetchLibraries() {
      try {
        const response = await axios.get('https://qahtikxjpno4pbqu6czpjkwvxa0ezjfs.lambda-url.us-east-1.on.aws');
        this.libraries = response.data;
      } catch (error) {
        console.error('Error fetching Libraries:', error);
      }
    },
    async fetchResorts() {
      try {
        const response = await axios.get('https://z2miumn35tlqjakzmbv43eu4om0ldovd.lambda-url.us-east-1.on.aws');
        this.resorts = response.data;
      } catch (error) {
        console.error('Error fetching Resorts:', error);
      }
    },
    async fetchSwimmingPool() {
      try {
        const response = await axios.get('https://634htx6b6zqcpii4o5ofwqlmca0yiljp.lambda-url.us-east-1.on.aws');
        this.swimmingpool = response.data;
      } catch (error) {
        console.error('Error fetching Swimming Pools:', error);
      }
    }
  },
  mounted() {
    this.fetchHealthFacilities();
    this.fetchSchools();
    this.fetchPlacesOfWorship();
    this.fetchPublicWiFiSites();
    this.fetchLibraries();
    this.fetchResorts();
    this.fetchSwimmingPool();
  }
};
</script>

<style scoped>
.tabs {
  display: flex;
  margin-bottom: 20px;
}
button {
  margin-right: 10px;
}
.table-responsive {
  margin-top: 20px;
}
</style>
